// Import all of Bootstrap's JS
import * as bootstrap from 'bootstrap';
import { gsap } from "gsap";

import Swiper from 'swiper';
import { Navigation, Pagination } from 'swiper/modules';

document.addEventListener('DOMContentLoaded', function() {

    //teammate superpower
    document.querySelectorAll('.btn-superpower').forEach(button => {
        button.addEventListener('click', function() {
            const powerBlock = this.closest('.teammate').querySelector('.power');

            if (powerBlock) {
                powerBlock.classList.toggle('active');
            }
        });
    });

    var swiperSmartTrek = new Swiper(".smarttrek-slider", {
        modules: [Navigation, Pagination],
        loop: true,
        speed: 700,
        lazy: true,
        pagination: {
            el: ".smarttrek_slider-pagination",
            clickable: true,
        },
        navigation: {
            nextEl: '.smart-button-next',
            prevEl: '.smart-button-prev',
        },
        autoplay: {
            delay: 20000,
            disableOnInteraction: false,
        },
    });

    const mobSliderFeatures = new Swiper('.mob-slider-features', {
        modules: [Navigation, Pagination],
        slidesPerView: 1,
        spaceBetween: 16,
        pagination: {
            el: '.slider-features-pagination',
            clickable: true,
        },
        breakpoints: {
            0: {
                slidesPerView: 1.1,
            },
        }
    });

    const sliderMainServices = new Swiper('.slider-main-services', {
        modules: [Navigation, Pagination],
        slidesPerView: 1,
        spaceBetween: 10,
        pagination: {
            el: '.main-services-pagination',
            clickable: true,
        },
        breakpoints: {
            0: {
                slidesPerView: 1.1,
            },
        }
    });

    const sliderTeammate = new Swiper('.slider-teammate', {
        modules: [Navigation, Pagination],
        slidesPerView: 1,
        spaceBetween: 16,
        pagination: {
            el: '.slider-teammate-pagination',
            clickable: true,
        },
        breakpoints: {
            0: {
                slidesPerView: 1.1,
            },
        }
    });

    // go top page
    const topPage = document.getElementById("top-page");
    if(topPage) {
        topPage.addEventListener("click", function () {
            window.scrollTo({
                top: 0,
                behavior: "smooth"
            });
        });
    }

    // mob menu
    const showMobBtn = document.querySelector('.show-mob-btn');
    const closeMobBtn = document.querySelector('.close-mob-btn');
    const showCheckbox = showMobBtn?.querySelector('input[type="checkbox"]');
    const closeCheckbox = closeMobBtn?.querySelector('input[type="checkbox"]');

    if (showMobBtn) {
        showMobBtn.addEventListener('click', function () {
            showMobBtn.style.display = 'none';
            setTimeout(function () {
                closeCheckbox.checked = true;
            }, 10);
        });

        closeMobBtn.addEventListener('click', function () {
            showMobBtn.style.display = 'flex';
            setTimeout(function () {
                showCheckbox.checked = false;
            }, 10);
        });
    }

    //anchor
    document.querySelectorAll('a[href^="#"]').forEach(anchor => {
        anchor.addEventListener('click', function (e) {
            e.preventDefault();

            const targetId = this.getAttribute('href').substring(1);
            const targetElement = document.getElementById(targetId);

            if (targetElement) {
                const offset = 70;
                const elementPosition = targetElement.offsetTop;
                const offsetPosition = elementPosition - offset;

                window.scrollTo({
                    top: offsetPosition,
                    behavior: 'smooth'
                });
            }
        });
    });

    // close mob menu
    document.querySelectorAll('.mob-menu-list a').forEach(anchor => {
        anchor.addEventListener('click', function () {
            const closeButton = document.querySelector('.close-mob-btn');
            if (closeButton) {
                closeButton.click();
            }
        });
    });


});
